<template>
  <div>
    <template v-if="admins.length > 0">
      <VRow>
        <VCol align="right">
          <VBtn
            color="primary"
            :to="{ name : Names.R_ACCOUNT_SYS_COMPANY_USER_ADD }"
          >
            <VIcon left>
              fal fa-plus
            </VIcon>

            Добавить администратора
          </VBtn>
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <AdminTable
            :items="modifiedAdmins"
            :loading="loading"
            @to-user="goToUser($event)"
            @delete-admin="openDeletePopup($event)"
          />
        </VCol>
      </VRow>
    </template>
    <template v-else-if="!loading">
      <VRow>
        <VCol
          align="center"
          justify="center"
        >
          <VImg
            width="102"
            :src="require('@/assets/male-mexican-hat.png')"
          />
          <div class="empty-company">
            Нет администраторов в компании
          </div>
          <RouterLink :to="{ name : Names.R_ACCOUNT_SYS_COMPANY_USER_ADD }">
            Добавить администратора
          </RouterLink>
        </VCol>
      </VRow>
    </template>

    <DeleteAdminDialog
      v-model="isShowDeleteDialog"
      :admin="deletingAdmin"
      @delete="deleteAdmin"
    />
  </div>
</template>

<script>
import AdminTable from '../../../components/v2/AdminsTable.vue';
import DeleteAdminDialog from '../../../components/dialogs/DeleteAdminDialog.vue';

export default {
  name: 'CompanyAdmins',
  components: { AdminTable, DeleteAdminDialog },
  inject: ['Names'],
  props: {
    accountId: { type: String, required: true },
    companyId: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      isShowDeleteDialog: false,
      admins: [],
      deletingAdmin: null,
    };
  },
  computed: {
    modifiedAdmins() {
      return this.admins.map((user) => ({
        ...user,
        fullName: this.fullName(user),
      }));
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    fullName(user) {
      return [user.lastName, user.firstName, user.middleName].filter((n) => n).join(' ');
    },
    openDeletePopup(admin) {
      this.deletingAdmin = admin;
      this.isShowDeleteDialog = true;
    },
    goToUser(user) {
      this.$router.push({
        name: this.Names.R_AUTHENTICATION_USER,
        params: {
          userId: user.id,
        },
      });
    },
    async deleteAdmin() {
      try {
        this.loading = true;
        const data = { id: this.companyId, userId: this.deletingAdmin.id };
        await this.$di.api.Account.companyRemoveUser(data);

        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async fetch() {
      try {
        this.loading = true;
        const app = new this.$di.ddd.Accounts();
        const [adminsIds] = await app.services.company.getUsers({ id: this.companyId });

        if (adminsIds.length) {
          const response = await this.$di.api.Authentication.getUsers({ ids: adminsIds });
          this.admins = response.users;
        } else {
          this.admins = [];
        }
      } catch (err) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.empty-company {
  margin: 12px 0 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.3px;
  color: #0b1218;
}
</style>
