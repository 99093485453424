<template>
  <VDialog
    :value="value"
    max-width="536px"
    @input="$emit('input', $event)"
  >
    <VCard>
      <VCardTitle>
        Удалить администратора?
      </VCardTitle>

      <VCardText>
        Администратор <span class="tt-danger--text">{{ fullName }}</span> будет удален.
        <br>Действительно хотите удалить администратора?
      </VCardText>

      <VCardActions>
        <VSpacer />

        <TTBtn
          color="tt-secondary"
          large
          @click="close"
        >
          Нет
        </TTBtn>

        <TTBtn
          color="tt-danger"
          large
          @click="deleteAdmin"
        >
          Да
        </TTBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'DeleteAdminDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    admin: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fullName() {
      return this.admin?.fullName;
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    deleteAdmin() {
      this.$emit('delete', this.admin);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item__title {
    font-size: 0.875em;
  }

  .v-dialog {
    .v-card {
      .v-card__title {
        font-size: 18px;
        word-break: break-word;
      }
    }
  }

  .v-card__actions > .v-btn.v-btn {
    padding: inherit;
  }
}
</style>
