<template>
  <TTDataTable
    :headers="headers"
    :items="items"
    :loading="loading"
    hide-default-footer
    disable-pagination
  >
    <template #item.fullName="{ item }">
      <div
        class="user-link tt-light-blue--text"
        @click="$emit('to-user', item)"
      >
        {{ item.fullName }}
      </div>
    </template>
    <template #item.actions="{ item }">
      <div class="d-flex justify-center">
        <VIcon
          class="tt-red--text mr-3"
          left
          @click="$emit('delete-admin', item)"
        >
          fal fa-trash-alt
        </VIcon>
      </div>
    </template>
  </TTDataTable>
</template>

<script>
export default {
  name: 'AdminsTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'ФИО', value: 'fullName' },
        { text: 'Действия', value: 'actions', sortable: false },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.user-link {
  cursor: pointer;
}
.table-v-menu__wrapper {
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;
}
</style>
